import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./reducers/authReducer"
import Alert from "./reducers/alertReducer"
import Shared from "./reducers/sharedReducer"
import Users from "./reducers/usersReducer"
import MaintenanceUsers from "./reducers/maintenanceUsersReducer"
import Banners from "./reducers/bannersReducer"
import Partners from "./reducers/partnersReducer"
import Attributes from "./reducers/attributeReducer"
import RoomTypes from "./reducers/roomTypesReducer"
import Services from "./reducers/servicesReducer"
import ServiceItems from "./reducers/serviceItemsReducer"
import Projects from "./reducers/projectsReducer"
import Units from "./reducers/unitReducer"
import News from "./reducers/newsReducer"
import BuyingPayments from "./reducers/buyingPaymentsReducer"
import GlobalSettings from "./reducers/globalSettingsReducer"
import Cities from "./reducers/citiesReducer"
import Customers from "./reducers/customersReducer"
import CustomerSupport from "./reducers/customerSupportReducer"
import Notifications from "./reducers/notificationsReducer"
import CustomerInterest from "./reducers/customerInterestReducer"
import Transactions from "./reducers/transactionsReducer"
import Common from "./reducers/commonReducer"
import AdminNotifications from "./reducers/adminNotificationReducer"
import ContentManagements from "./reducers/contentManagementReducer"
import BankFinance from "./reducers/bankFinanceReducer"

const rootReducer = combineReducers({
  Layout,
  Alert,
  Login,
  Shared,
  Users,
  MaintenanceUsers,
  Banners,
  Partners,
  Attributes,
  RoomTypes,
  Projects,
  Units,
  Services,
  ServiceItems,
  News,
  BuyingPayments,
  GlobalSettings,
  Cities,
  Customers,
  CustomerSupport,
  Notifications,
  CustomerInterest,
  Transactions,
  Common,
  AdminNotifications,
  ContentManagements,
  BankFinance,
})

export default rootReducer
