import { createSlice } from "@reduxjs/toolkit"
import {
  changeBankFinanceReq,
  getAllBankFinanceReq,
  getBankFinanceReqDetails,
} from "store/actions/bankFinanceAction"

const initialState = {
  loading: false,
  isLoading: false,
  perPage: 10,
  totalCount: null,
  totalPages: null,
  currentPage: null,
  bankFinances: [],
  bankFinanceDetails: {},
}

const bankFinanceSlice = createSlice({
  name: "bank finance",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // GET ALL
    builder
      .addCase(getAllBankFinanceReq.pending, state => {
        state.loading = true
      })
      .addCase(getAllBankFinanceReq.fulfilled, (state, action) => {
        state.loading = false
        const {
          statusCode,
          success,
          data,
          totalCount,
          totalPages,
          currentPage,
        } = action.payload || {}
        if (statusCode === 200 && success) {
          state.bankFinances = data
          state.totalCount = totalCount
          state.totalPages = totalPages
          state.currentPage = currentPage
        }
      })
      .addCase(getAllBankFinanceReq.rejected, (state, action) => {
        state.loading = false
      })

    builder
      .addCase(changeBankFinanceReq.pending, state => {
        state.isLoading = true
      })
      .addCase(changeBankFinanceReq.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload || {}
        if (statusCode === 200 && success) {
          const { id, status } = action.meta.arg
          state.bankFinances = state.bankFinances.map(item =>
            item._id === id
              ? {
                  ...item,
                  bankFinanceDetails: {
                    ...item.bankFinanceDetails,
                    bankFinanceStep: Number(status),
                  },
                }
              : item
          )
        }
      })
      .addCase(changeBankFinanceReq.rejected, (state, action) => {
        state.isLoading = false
      })

    builder
      .addCase(getBankFinanceReqDetails.pending, state => {
        state.loading = true
        state.bankFinanceDetails = {}
      })
      .addCase(getBankFinanceReqDetails.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success, data } = action.payload || {}
        if (statusCode === 200 && success) {
          state.bankFinanceDetails = data
        }
      })
      .addCase(getBankFinanceReqDetails.rejected, (state, action) => {
        state.loading = false
      })
  },
})

export default bankFinanceSlice.reducer
