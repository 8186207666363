import { bankFinanceStatusOptions } from "components/Common/constants"
import DetailsInnerDiv from "components/Shared/DetailsInnerDiv"
import DetailsTitle from "components/Shared/DetailsTitle"
import FormateDate from "components/Shared/FormateDate"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { ShimmerThumbnail } from "react-shimmer-effects"
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap"
import { getBankFinanceReqDetails } from "store/actions/bankFinanceAction"

const BankFinanceRequestDetails = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  document.title = t("Bank Finance Request Details | Takween")
  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")

  const { loading, bankFinanceDetails } = useSelector(
    state => state.BankFinance
  )

  useEffect(() => {
    const payload = {
      id,
    }
    const getDetails = () => {
      dispatch(getBankFinanceReqDetails(payload))
    }

    getDetails()
  }, [])

  const getReqStatus = status =>
    bankFinanceStatusOptions.find(option => option.value === status) || {}

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading ? (
                    <ShimmerThumbnail
                      height={1000}
                      rounded
                      className={"mb-0"}
                    />
                  ) : (
                    <>
                      <DetailsTitle
                        title={t("Bank Finance Request Details")}
                        isBackButton={true}
                      />
                      <Row className="g_details">
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Id")}
                            value={bankFinanceDetails?._id || "-"}
                          />
                        </Col>
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Booking Number")}
                            value={bankFinanceDetails?.bookingNo || "-"}
                          />
                        </Col>
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("User Phone Number")}
                            value={bankFinanceDetails?.userId?.phone || "-"}
                          />
                        </Col>
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Project Name")}
                            value={
                              language === "sa"
                                ? bankFinanceDetails?.projectId?.nameAr
                                : bankFinanceDetails?.projectId?.name
                            }
                          />
                        </Col>

                        <hr className="mt-4" />

                        <Col md={12}>
                          <h4 className="fs_24">{t("Unit Details")}</h4>
                        </Col>

                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Unit Name")}
                            value={
                              language === "sa"
                                ? bankFinanceDetails?.unitId?.nameAr
                                : bankFinanceDetails?.unitId?.name || "-"
                            }
                          />
                        </Col>
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Total Price")}
                            value={`${
                              bankFinanceDetails?.unitId?.priceDetails
                                ?.totalPrice
                            } ${t("SAR")}`}
                          />
                        </Col>
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Unit Price")}
                            value={`${
                              bankFinanceDetails?.unitId?.priceDetails
                                ?.unitPrice
                            } ${t("SAR")}`}
                          />
                        </Col>
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Unit Real Estate Tax")}
                            value={`${
                              bankFinanceDetails?.unitId?.priceDetails
                                ?.realEstateTax
                            } ${t("SAR")}`}
                          />
                        </Col>
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Unit Type")} // VILLA, APARTMENT
                            value={
                              t(
                                bankFinanceDetails?.unitId?.generalDetails
                                  ?.unitType
                              ) || "-"
                            }
                          />
                        </Col>
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Unit Area")}
                            value={`${bankFinanceDetails?.unitId?.generalDetails?.area} ${bankFinanceDetails?.unitId?.generalDetails?.areaUnit}`}
                          />
                        </Col>
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Unit Address")}
                            value={
                              language === "sa"
                                ? bankFinanceDetails?.unitId?.addressAr
                                : bankFinanceDetails?.unitId?.address
                            }
                          />
                        </Col>
                        {/* <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Total Floors")}
                            value={
                              bankFinanceDetails?.unitId?.generalDetails
                                ?.totalNumberOfFloors || "-"
                            }
                          />
                        </Col> */}
                        {/* <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Floor Number")}
                            value={
                              bankFinanceDetails?.unitId?.generalDetails
                                ?.floorNo || "-"
                            }
                          />
                        </Col> */}
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Door Password")}
                            value={
                              bankFinanceDetails?.unitId?.generalDetails
                                ?.doorPassword || "-"
                            }
                          />
                        </Col>

                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Status")}
                            value={t(
                              getReqStatus(
                                bankFinanceDetails?.bankFinanceDetails
                                  ?.bankFinanceStep
                              )?.label || "-"
                            )}
                            valueClass={`status_tag fit_content ${
                              getReqStatus(
                                bankFinanceDetails?.bankFinanceDetails
                                  ?.bankFinanceStep
                              )?.className || ""
                            }`}
                          />
                        </Col>

                        {bankFinanceDetails?.unitId?.roomDetails?.length >
                          0 && (
                          <Col lg={12}>
                            <h4 className="fs_24 mb-3">{t("Room Details")}</h4>

                            <Table className="table mb-0">
                              <thead>
                                <tr>
                                  <th>{t("#")}</th>
                                  <th>{t("Name")}</th>
                                  <th>{t("Count")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {bankFinanceDetails?.unitId?.roomDetails?.map(
                                  (item, index) => (
                                    <tr key={item._id}>
                                      <th scope="row">{index + 1}</th>
                                      <td>
                                        {language === "sa"
                                          ? item?.roomType?.nameAr
                                          : item?.roomType?.name}
                                      </td>
                                      <td>{item?.count}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </Col>
                        )}

                        <hr className="mt-4" />

                        <Col md={12}>
                          <h4 className="fs_24">{t("Bank Finance Details")}</h4>
                        </Col>
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Initiate Payment")}
                            value={
                              bankFinanceDetails?.bankFinanceDetails
                                ?.initiatePayment === "bankFinance"
                                ? t("Bank Finance")
                                : bankFinanceDetails?.bankFinanceDetails
                                    ?.initiatePayment
                            }
                          />
                        </Col>
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Down Payment Amount")}
                            value={`${
                              bankFinanceDetails?.bankFinanceDetails
                                ?.downPaymentAmount
                            } ${t("SAR")}`}
                          />
                        </Col>
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Down Payment Receipts")}
                            value={t("View")}
                            isPdfIcon={true}
                            isLink={true}
                            redirectLink={
                              bankFinanceDetails?.sadadReceipts?.bankFinance
                            }
                          />
                        </Col>
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Amount To Be Financed")}
                            value={`${
                              bankFinanceDetails?.bankFinanceDetails?.remaining
                            } ${t("SAR")}`}
                          />
                        </Col>
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Total")}
                            value={`${
                              bankFinanceDetails?.bankFinanceDetails?.total
                            } ${t("SAR")}`}
                          />
                        </Col>

                        {bankFinanceDetails?.bankFinanceDetails
                          ?.bankFinanceDownPaymentApprovedAt && (
                          <Col lg={4}>
                            <DetailsInnerDiv
                              label={t("Down Payment Approved At")}
                              value={FormateDate(
                                bankFinanceDetails?.bankFinanceDetails
                                  ?.bankFinanceDownPaymentApprovedAt
                              )}
                            />
                          </Col>
                        )}

                        {bankFinanceDetails?.bankFinanceDetails
                          ?.bankFinanceDownPaymentRejectedAt && (
                          <Col lg={4}>
                            <DetailsInnerDiv
                              label={t("Down Payment Rejected At")}
                              value={FormateDate(
                                bankFinanceDetails?.bankFinanceDetails
                                  ?.bankFinanceDownPaymentRejectedAt
                              )}
                            />
                          </Col>
                        )}

                        {bankFinanceDetails?.bankFinanceDetails
                          ?.bankFinanceRequestDeadline && (
                          <Col lg={4}>
                            <DetailsInnerDiv
                              label={t("Request Deadline")}
                              value={FormateDate(
                                bankFinanceDetails?.bankFinanceDetails
                                  ?.bankFinanceRequestDeadline
                              )}
                            />
                          </Col>
                        )}

                        {bankFinanceDetails?.bankFinanceDetails
                          ?.bankFinanceRequestApprovedAt && (
                          <Col lg={4}>
                            <DetailsInnerDiv
                              label={t("Request Approved At")}
                              value={FormateDate(
                                bankFinanceDetails?.bankFinanceDetails
                                  ?.bankFinanceRequestApprovedAt
                              )}
                            />
                          </Col>
                        )}

                        {bankFinanceDetails?.bankFinanceDetails
                          ?.bankFinanceRequestRejectedAt && (
                          <Col lg={4}>
                            <DetailsInnerDiv
                              label={t("Request Rejected At")}
                              value={FormateDate(
                                bankFinanceDetails?.bankFinanceDetails
                                  ?.bankFinanceRequestRejectedAt
                              )}
                            />
                          </Col>
                        )}

                        {bankFinanceDetails?.bankFinanceDetails
                          ?.bankFinanceRequestCompletedAt && (
                          <Col lg={4}>
                            <DetailsInnerDiv
                              label={t("Request Completed At")}
                              value={FormateDate(
                                bankFinanceDetails?.bankFinanceDetails
                                  ?.bankFinanceRequestCompletedAt
                              )}
                            />
                          </Col>
                        )}

                        <hr className="mt-4" />

                        <Col md={12}>
                          <h4 className="fs_24">{t("Buyer Details")}</h4>
                        </Col>

                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("First Name")}
                            value={bankFinanceDetails?.buyerDetails?.firstName}
                          />
                        </Col>
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Last Name")}
                            value={bankFinanceDetails?.buyerDetails?.lastName}
                          />
                        </Col>
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Is Have Account In Riyadh Bank")}
                            value={
                              bankFinanceDetails?.buyerDetails
                                ?.haveAccountInRiyadhBank
                                ? t("Yes")
                                : t("No")
                            }
                          />
                        </Col>
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("National Id")}
                            value={bankFinanceDetails?.buyerDetails?.nationalId}
                          />
                        </Col>
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Email")}
                            value={bankFinanceDetails?.buyerDetails?.email}
                          />
                        </Col>
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Total Salary")}
                            value={`${
                              bankFinanceDetails?.buyerDetails?.totalSalary
                            } ${t("SAR")}`}
                          />
                        </Col>
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Work Details")}
                            value={
                              bankFinanceDetails?.buyerDetails?.workDetails ||
                              "-"
                            }
                          />
                        </Col>
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Loan Type")}
                            value={
                              bankFinanceDetails?.buyerDetails?.loanType || "-"
                            }
                          />
                        </Col>
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Loan Period")}
                            value={
                              bankFinanceDetails?.buyerDetails?.loanPeriod ||
                              "-"
                            }
                          />
                        </Col>
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Loan Period Type")}
                            value={
                              bankFinanceDetails?.buyerDetails
                                ?.loanPeriodType || "-"
                            }
                            valueClass="text-capitalize"
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BankFinanceRequestDetails
