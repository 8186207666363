import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Card, Col, Container, Modal, Row, Table } from "reactstrap"
import { useLocation, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import useFilters from "components/Shared/useFilters"
import Title from "components/Shared/Title"
import Filters from "components/Shared/Filters"
import {
  ascDescOptions,
  baseFilterOptions,
  paymentStatus,
} from "components/Common/constants"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import Paginations from "components/Common/Pagination"
import DetailsInnerDiv from "components/Shared/DetailsInnerDiv"
import {
  DownloadCSV,
  DownloadXLSX,
  fileAccept,
  getDateRange,
  handleFileUpload,
} from "components/Common/Utils"
import CustomErrorMessage from "components/Shared/CustomErrorMessage"
import { Field, Form, Formik } from "formik"
import CustomLabel from "components/Shared/CustomLabel"
import { deedSubmissionSchema } from "components/Common/validation"
import GalleryIcon from "assets/images/icons/gallery.svg"
import {
  getAllBuyingPayments,
  getAllDeedRecords,
  getBuyingPaymentDetails,
  uploadDeedDocuments,
} from "store/actions/buyingPaymentsAction"
import Spinners from "components/Shared/Spinners"
import ViewIcon from "assets/images/icons/view.svg"
import IsLoading from "components/Shared/IsLoading"
import FormateDate from "components/Shared/FormateDate"
import { showAlert } from "store/reducers/alertReducer"
import { getUnitsList } from "store/actions/unitsAction"
import { exportData } from "helpers/api_helper"
import { EXPORT_DATA } from "helpers/url_helper"

const DeedRequest = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Deed Request | Takween")

  const { search } = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const pageNumber = Number(new URLSearchParams(search).get("page")) || 1

  const {
    loading,
    isLoading,
    isGettingDetails,
    buyingPayments,
    buyingPaymentDetails,
    totalCount,
    totalPages,
    currentPage,
    perPage,
  } = useSelector(state => state.BuyingPayments)

  const [
    filters,
    keyword,
    setKeyword,
    dateRange,
    setDateRange,
    flatpickrRef,
    selectedId,
    setSelectedId,
    itemIdToDelete,
    setItemIdToDelete,
    showDeleteModal,
    setShowDeleteModal,
    isApiCalled,
    setIsApiCalled,
    handleFilterChange,
    resetFilters,
  ] = useFilters({
    orderBy: 2,
  })

  const [modal, setModal] = useState(false)
  const [modalType, setModalType] = useState(null)

  useEffect(() => {
    fetchAllDeedRequest()
    fetchUnitsList()
  }, [])

  const fetchAllDeedRequest = page => {
    const { orderBy, unit, status } = filters
    const updatedUnits = unit?.map(item => item.value) || []

    const payload = {
      perPage,
      pageNo: page || pageNumber,
      orderBy,
      keyword,
      dateRange: getDateRange(dateRange),
    }
    dispatch(getAllDeedRecords(payload))
    setIsApiCalled(true)
    navigate(keyword ? `/deed-request?keyword=${keyword}` : `/deed-request`)
  }

  const fetchUnitsList = () => {
    const payload = {
      project: [],
      statusType: [1, 2, 3, 4],
    }
    dispatch(getUnitsList(payload))
  }

  const filterOptions = [...ascDescOptions]

  useEffect(() => {
    modal && modalType === "VIEW_DETAILS" && fetchPaymentDetails()
  }, [modal, modalType])

  const fetchPaymentDetails = () => {
    const payload = {
      id: selectedId,
    }
    dispatch(getBuyingPaymentDetails(payload))
  }

  useEffect(() => {
    if (isApiCalled) {
      handleSearch()
    }
  }, [keyword, filters])

  useEffect(() => {
    if (isApiCalled) {
      if (dateRange.min && dateRange.max) handleSearch()
    }
  }, [dateRange])

  const handleSearch = () => {
    fetchAllDeedRequest(1)
  }

  const onPageChange = page => {
    fetchAllDeedRequest(page)
  }

  const toggle = () => {
    modal && setModalType(null)
    setModal(!modal)
  }

  const handleDeedUpload = values => {
    const { deedFile } = values

    let data = new FormData()
    data.append("id", selectedId)
    data.append("deedDoc", deedFile)

    dispatch(uploadDeedDocuments({ data, id: selectedId }))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setSelectedId(null)
          toggle()
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  const getExportFilters = () => {
    const { unit, status } = filters
    const updatedUnits = unit?.map(item => item.value) || []

    return {
      status: status,
      unit: updatedUnits,
      customer: [],
      paymentType: [7],
    }
  }

  const handleDownloadCSV = async () => {
    const payload = {
      type: "BuyingDetails",
      exportType: "csv",
      filter: getExportFilters(),
    }

    const response = await exportData(EXPORT_DATA, payload)
    if (response) {
      const csvData = response
      DownloadCSV(csvData, t("Deed Request"))
    }
  }

  const handleDownloadXLSX = async () => {
    const payload = {
      type: "BuyingDetails",
      exportType: "xlsx",
      filter: getExportFilters(),
    }

    const response = await exportData(EXPORT_DATA, payload)
    if (response) {
      const csvData = response
      DownloadXLSX(csvData, t("Deed Request"))
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <Title
                  title={t("Deed Request")}
                  isExport={true}
                  handleDownloadCSV={handleDownloadCSV}
                  handleDownloadXLSX={handleDownloadXLSX}
                />
                <Filters
                  isGlobalFilter={true}
                  SearchPlaceholder={t("Search")}
                  keyword={keyword}
                  setKeyword={setKeyword}
                  filters={filterOptions}
                  selectedFilters={filters}
                  handleFilterChange={handleFilterChange}
                  handleReset={() => resetFilters()}
                  isRangeDate={true}
                  flatpickrRef={flatpickrRef}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  isResetButton={true}
                />

                {loading ? (
                  <Spinners />
                ) : buyingPayments.length === 0 ? (
                  <NoDataAvailable />
                ) : (
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="full_th">
                        <tr>
                          <th>{t("#")}</th>
                          <th>{t("Name")}</th>
                          {/* <th>{t("Last Name")}</th> */}
                          <th>{t("Unit Name")}</th>
                          <th>{t("Booking Id")}</th>
                          <th>{t("Status")}</th>
                          <th>{t("Action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {buyingPayments.map((item, index) => (
                          <tr key={item._id}>
                            <th scope="row">
                              {(currentPage - 1) * perPage + index + 1}
                            </th>
                            <td>{item?.userData?.name}</td>{" "}
                            {/* <td>{item?.buyerDetails?.lastName}</td> */}
                            <td>
                              {language === "sa"
                                ? item?.unitData?.nameAr
                                : item?.unitData?.name || "-"}
                            </td>
                            <td>{item.bookingNo || "-"}</td>
                            <td>
                              {!item?.deedDocumentDetails
                                ?.requestedByCustomerAt ? (
                                <p className="sold_status status_tag fit_content">
                                  {t("Request Pending")}
                                </p>
                              ) : !item?.deedDocumentDetails?.uploadAt ? (
                                <p className="sold_status status_tag fit_content">
                                  {t("Pending")}
                                </p>
                              ) : item?.deedDocumentDetails?.uploadAt ? (
                                <p className="rended_status status_tag">
                                  {t("Sent")}
                                </p>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>
                              <img
                                src={ViewIcon}
                                alt="Trash Icon"
                                width={24}
                                height={24}
                                className="cursor-pointer me-3"
                                onClick={() => {
                                  setSelectedId(item._id)
                                  setModalType("VIEW_DETAILS")
                                  toggle()
                                }}
                              />
                              {item?.deedDocumentDetails
                                ?.requestedByCustomerAt &&
                                !item?.deedDocumentDetails?.uploadAt && (
                                  <button
                                    className="upload_btn"
                                    onClick={() => {
                                      setSelectedId(item._id)
                                      toggle()
                                      setModalType("UPLOAD_DEED")
                                    }}
                                  >
                                    {t("Upload")}
                                  </button>
                                )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card>
            </Col>
            {!loading && buyingPayments.length > 0 && (
              <Paginations
                currentPage={currentPage}
                totalPages={totalPages}
                totalCount={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </Row>
        </Container>
      </div>

      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        className={`g_modal ${
          modalType === "VIEW_DETAILS" ? "w_850" : "w_600"
        }`}
      >
        <h2 className="modal_title">
          {modalType === "VIEW_DETAILS"
            ? t("Deed Request Details")
            : t("Upload Deed Document")}
        </h2>
        <button type="button" onClick={toggle} className="modal_close_btn">
          <i className="mdi mdi-close"></i>
        </button>
        <div>
          {/* MODAL FOR VIEW DETAILS */}
          {modalType === "VIEW_DETAILS" &&
            (isGettingDetails ? (
              <IsLoading />
            ) : (
              <Row>
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Name")}
                    value={buyingPaymentDetails?.userId?.name || "-"}
                  />
                </Col>
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Phone Number")}
                    value={buyingPaymentDetails?.userId?.phone || "-"}
                  />
                </Col>
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Email")}
                    value={buyingPaymentDetails?.buyerDetails?.email || "-"}
                  />
                </Col>
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Unit Name")}
                    value={
                      language === "sa"
                        ? buyingPaymentDetails?.unitId?.nameAr
                        : buyingPaymentDetails?.unitId?.name || "-"
                    }
                  />
                </Col>
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Booking Number")}
                    value={buyingPaymentDetails?.bookingNo || "-"}
                  />
                </Col>
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Total Payment")}
                    value={`${
                      buyingPaymentDetails?.paymentDetails?.total +
                      buyingPaymentDetails?.paymentDetails?.taxExemption
                    } ${t("SAR")}`}
                  />
                </Col>

                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Status")}
                    value={
                      !buyingPaymentDetails?.deedDocumentDetails
                        ?.requestedByCustomerAt
                        ? t("Request Pending")
                        : !buyingPaymentDetails?.deedDocumentDetails?.uploadAt
                        ? t("Pending")
                        : buyingPaymentDetails?.deedDocumentDetails?.uploadAt
                        ? t("Sent")
                        : "-"
                    }
                    valueClass={
                      !buyingPaymentDetails?.deedDocumentDetails
                        ?.requestedByCustomerAt
                        ? "pending_color"
                        : !buyingPaymentDetails?.deedDocumentDetails?.uploadAt
                        ? "pending_color"
                        : buyingPaymentDetails?.deedDocumentDetails?.uploadAt
                        ? "active_color"
                        : "-"
                    }
                  />
                </Col>

                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Customer ID")}
                    value={
                      buyingPaymentDetails?.buyerDetails?.nationalId || "-"
                    }
                  />
                </Col>

                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Deed Number")}
                    value={
                      buyingPaymentDetails?.unitId?.deedDetails?.deedNumber ||
                      "-"
                    }
                  />
                </Col>

                {/* {buyingPaymentDetails?.unitId?.deedDetails?.document && ( */}
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Original Deed Doc")}
                    value={"View"}
                    isPdfIcon={true}
                    isLink={true}
                    redirectLink={
                      buyingPaymentDetails?.unitId?.deedDetails?.document
                    }
                  />
                </Col>
                {/* )} */}

                {/* {buyingPaymentDetails?.deedDocumentDetails?.warrantiesDoc && ( */}
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Warranties Doc")}
                    value={"View"}
                    isPdfIcon={true}
                    isLink={true}
                    redirectLink={
                      buyingPaymentDetails?.deedDocumentDetails?.warrantiesDoc
                    }
                  />
                </Col>
                {/* // )} */}

                {/* {buyingPaymentDetails?.deedDocumentDetails?.paymentReceipts && ( */}
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Payment Receipts")}
                    value={"View"}
                    isPdfIcon={true}
                    isLink={true}
                    redirectLink={
                      buyingPaymentDetails?.deedDocumentDetails?.paymentReceipts
                    }
                  />
                </Col>
                {/* )} */}

                {/* {buyingPaymentDetails?.ownershipDocumentsDetails?.waterBill && ( */}
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Water Bill")}
                    value={t("Water Bill Document")}
                    isPdfIcon={true}
                    isLink={true}
                    redirectLink={
                      buyingPaymentDetails?.ownershipDocumentsDetails?.waterBill
                    }
                  />
                </Col>
                {/* )} */}
                {/* {buyingPaymentDetails?.ownershipDocumentsDetails
                  ?.electricityBill && ( */}
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Electricity Bill")}
                    value={t("Electricity Bill Document")}
                    isPdfIcon={true}
                    isLink={true}
                    redirectLink={
                      buyingPaymentDetails?.ownershipDocumentsDetails
                        ?.electricityBill
                    }
                  />
                </Col>
                {/* )} */}

                {/* {buyingPaymentDetails?.RETTCertificate?.attachment && ( */}
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("RETT Certificate")}
                    value={"View"}
                    isPdfIcon={true}
                    isLink={true}
                    redirectLink={
                      buyingPaymentDetails?.RETTCertificate?.attachment
                    }
                  />
                </Col>
                {/* )} */}
                {/* {buyingPaymentDetails?.sadadReceipts?.sadadReceiptsDoc && ( */}
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("SADAD Down Payment")}
                    value={"View"}
                    isPdfIcon={true}
                    isLink={true}
                    redirectLink={
                      buyingPaymentDetails?.sadadReceipts?.downPayment
                    }
                  />
                </Col>
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("SADAD Full Payment")}
                    value={"View"}
                    isPdfIcon={true}
                    isLink={true}
                    redirectLink={
                      buyingPaymentDetails?.sadadReceipts?.fullPayment
                    }
                  />
                </Col>
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("SADAD Tax Payment")}
                    value={"View"}
                    isPdfIcon={true}
                    isLink={true}
                    redirectLink={
                      buyingPaymentDetails?.sadadReceipts?.taxPayment
                    }
                  />
                </Col>
                {/* // )} */}
                {/* {buyingPaymentDetails?.deedDocumentDetails?.deedDoc && ( */}
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Uploaded Deed Doc")}
                    value={"View"}
                    isPdfIcon={true}
                    isLink={true}
                    redirectLink={
                      buyingPaymentDetails?.deedDocumentDetails?.deedDoc
                    }
                  />
                </Col>
                {/* )} */}

                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Created At")}
                    value={FormateDate(buyingPaymentDetails?.createdAt)}
                  />
                </Col>
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Updated At")}
                    value={FormateDate(buyingPaymentDetails?.updatedAt)}
                  />
                </Col>
              </Row>
            ))}

          {modalType === "UPLOAD_DEED" && (
            <Row>
              <Col lg={12}>
                <Formik
                  initialValues={{
                    deedFile: "",
                    // warranties: "",
                    // paymentReceipt: "",
                  }}
                  validationSchema={deedSubmissionSchema}
                  onSubmit={handleDeedUpload}
                >
                  {({ values, errors, touched, setFieldValue }) => (
                    <Form>
                      <Row>
                        <Col lg={12}>
                          <div className="form_field mb-0">
                            <CustomLabel
                              htmlFor="deedFile"
                              label={t("Upload Deed")}
                              isRequired={true}
                            />
                            <label
                              htmlFor="deedFile"
                              className={`upload_label g_center d-flex cursor-pointer ${
                                errors.deedFile && touched.deedFile
                                  ? "border-danger text-danger"
                                  : ""
                              } `}
                            >
                              {values?.deedFile?.name ? (
                                <p>{values?.deedFile?.name}</p>
                              ) : (
                                <>
                                  <img
                                    src={GalleryIcon}
                                    alt="gallery Icon"
                                    className="mx-2"
                                  />
                                  {t("Upload File")}
                                </>
                              )}
                            </label>
                            <input
                              name="deedFile"
                              type="file"
                              id="deedFile"
                              accept={fileAccept}
                              className={`form-control d-none`}
                              onChange={event =>
                                handleFileUpload(
                                  event,
                                  setFieldValue,
                                  "deedFile"
                                )
                              }
                            />
                            <CustomErrorMessage name={"deedFile"} />
                          </div>
                        </Col>
                        <Col lg={12}>
                          <button
                            className="save_btn mt-4 ms-auto d-block"
                            type="submit"
                            disabled={isLoading}
                          >
                            {t("Upload")}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          )}
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default DeedRequest
