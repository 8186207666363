import { createAsyncThunk } from "@reduxjs/toolkit"
import { del, get, patch, post, put } from "helpers/api_helper"
import {
  CHANGE_BANK_FINANCE_REQ_STATUS,
  GET_ALL_BANK_FINANCE_REQ,
  GET_BANK_FINANCE_DETAILS,
} from "helpers/url_helper"

export const getAllBankFinanceReq = createAsyncThunk(
  GET_ALL_BANK_FINANCE_REQ,
  async payload => {
    const response = await post(GET_ALL_BANK_FINANCE_REQ, payload)
    return response
  }
)

export const changeBankFinanceReq = createAsyncThunk(
  CHANGE_BANK_FINANCE_REQ_STATUS,
  async payload => {
    const response = await post(CHANGE_BANK_FINANCE_REQ_STATUS, payload)
    return response
  }
)

export const getBankFinanceReqDetails = createAsyncThunk(
  GET_BANK_FINANCE_DETAILS,
  async payload => {
    const response = await post(GET_BANK_FINANCE_DETAILS, payload)
    return response
  }
)
