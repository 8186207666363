import React, { useEffect, useState } from "react"
import { Card, Col, Container, Modal, Row, Table } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import useFilters from "components/Shared/useFilters"
import {
  changeBankFinanceReq,
  getAllBankFinanceReq,
} from "store/actions/bankFinanceAction"
import Title from "components/Shared/Title"
import Filters from "components/Shared/Filters"
import {
  ascDescOptions,
  bankFinanceStatusOptions,
} from "components/Common/constants"
import Spinners from "components/Shared/Spinners"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import ViewIcon from "assets/images/icons/view.svg"
import Paginations from "components/Common/Pagination"
import { Form, Formik } from "formik"
import CustomLabel from "components/Shared/CustomLabel"
import CustomField from "components/Shared/CustomField"
import Select from "react-select"
import { bankFinanceStatusSchema } from "components/Common/validation"
import CustomErrorMessage from "components/Shared/CustomErrorMessage"
import { showAlert } from "store/reducers/alertReducer"

const BankFinanceRequest = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Bank Finance Request | Takween")

  const { search } = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const pageNumber = Number(new URLSearchParams(search).get("page")) || 1

  const {
    loading,
    isLoading,
    isGettingDetails,
    bankFinances,
    totalCount,
    totalPages,
    currentPage,
    perPage,
  } = useSelector(state => state.BankFinance)

  const [selectedItem, setSelectedItem] = useState({})

  const [
    filters,
    keyword,
    setKeyword,
    dateRange,
    setDateRange,
    flatpickrRef,
    selectedId,
    setSelectedId,
    itemIdToDelete,
    setItemIdToDelete,
    showDeleteModal,
    setShowDeleteModal,
    isApiCalled,
    setIsApiCalled,
    handleFilterChange,
    resetFilters,
  ] = useFilters({
    orderBy: 1,
  })

  const [modal, setModal] = useState(false)

  const toggle = () => {
    if (modal) {
      setSelectedItem({})
      setSelectedId(null)
    }
    setModal(!modal)
  }

  useEffect(() => {
    getAllData()
  }, [])

  const getAllData = page => {
    const { orderBy } = filters

    const payload = {
      perPage,
      pageNo: page || pageNumber,
      orderBy, // 1: asc , 2: desc
      unit: [],
      customer: [],
      keyword, //customer name, phone, bookingId
      dateRange: {},
    }
    dispatch(getAllBankFinanceReq(payload))
    setIsApiCalled(true)
    navigate(
      keyword
        ? `/bank-finance-request?keyword=${keyword}`
        : `/bank-finance-request`
    )
  }

  useEffect(() => {
    if (isApiCalled) {
      handleSearch()
    }
  }, [keyword, filters])

  const handleSearch = () => {
    getAllData(1)
  }

  const onPageChange = page => {
    getAllData(page)
  }

  const filterOptions = [...ascDescOptions]

  const getReqStatus = status =>
    bankFinanceStatusOptions.find(option => option.value === status) || {}

  const handleStatusChange = values => {
    const payload = {
      ...values,
      id: selectedId,
    }
    dispatch(changeBankFinanceReq(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          toggle()
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <Title title={t("Bank Finance Request")} />
                <div className="mt-2"></div>
                <Filters
                  isGlobalFilter={true}
                  SearchPlaceholder={t(
                    "Search By Customer Name, Phone, Booking Id"
                  )}
                  keyword={keyword}
                  setKeyword={setKeyword}
                  filters={filterOptions}
                  selectedFilters={filters}
                  handleFilterChange={handleFilterChange}
                  handleReset={() => resetFilters()}
                />
                {loading ? (
                  <Spinners />
                ) : bankFinances.length === 0 ? (
                  <NoDataAvailable />
                ) : (
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="full_th">
                        <tr>
                          <th>{t("#")}</th>
                          <th>{t("Booking Number")}</th>
                          <th>{t("Project Name")}</th>
                          <th>{t("Unit Name")}</th>
                          <th>{t("Buyer National Id")}</th>
                          <th>{t("Buyer Name")}</th>
                          <th>{t("Buyer Email")}</th>
                          <th>{t("Status")}</th>
                          <th>{t("Action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bankFinances.map((item, index) => (
                          <tr key={item._id}>
                            <th scope="row">
                              {(currentPage - 1) * perPage + index + 1}
                            </th>
                            <td>{item?.bookingNo}</td>
                            <td>
                              {language === "sa"
                                ? item?.projectData?.nameAr
                                : item?.projectData?.name}
                            </td>
                            <td>
                              {language === "sa"
                                ? item?.unitData?.nameAr
                                : item?.unitData?.name}
                            </td>
                            <td>{item?.buyerDetails?.nationalId}</td>
                            <td>{`${item?.buyerDetails?.firstName} ${item?.buyerDetails?.lastName}`}</td>
                            <td>{item?.buyerDetails?.email}</td>
                            <td>
                              <p
                                className={`${
                                  getReqStatus(
                                    item?.bankFinanceDetails?.bankFinanceStep
                                  )?.className
                                } status_tag fit_content`}
                              >
                                {t(
                                  getReqStatus(
                                    item?.bankFinanceDetails?.bankFinanceStep
                                  )?.label
                                )}
                              </p>
                            </td>
                            <td>
                              <div className="d-flex gap-2 align-items-center">
                                <img
                                  src={ViewIcon}
                                  alt="Trash Icon"
                                  width={24}
                                  height={24}
                                  className="cursor-pointer"
                                  onClick={() => {
                                    navigate(
                                      `/bank-finance-request-details?id=${item?._id}`
                                    )
                                  }}
                                />
                                {item?.bankFinanceDetails?.bankFinanceStep ===
                                  0 && (
                                  <button
                                    className="upload_btn fit_content"
                                    onClick={() => {
                                      setSelectedId(item._id)
                                      toggle()
                                      setSelectedItem(item)
                                    }}
                                  >
                                    {t("Change Status")}
                                  </button>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card>
            </Col>
            {!loading && bankFinances.length > 0 && (
              <Paginations
                currentPage={currentPage}
                totalPages={totalPages}
                totalCount={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        className={`g_modal w_600`}
      >
        <h2 className="modal_title">
          {t("Change Bank Finance Request Status")}
        </h2>
        <button type="button" onClick={toggle} className="modal_close_btn">
          <i className="mdi mdi-close"></i>
        </button>
        <Row>
          <Col lg={12}>
            <Formik
              initialValues={{
                status:
                  selectedItem?.bankFinanceDetails?.bankFinanceStep || null,
                note: "",
              }}
              validationSchema={bankFinanceStatusSchema}
              onSubmit={handleStatusChange}
            >
              {({ values, errors, touched, setFieldValue }) => (
                <Form>
                  <Row>
                    <Col lg={12}>
                      <div className="g_label mb-0">
                        <CustomLabel
                          htmlFor="status"
                          label={t("Select Status")}
                          isRequired={true}
                        />
                        <Select
                          value={bankFinanceStatusOptions
                            ?.map(option => ({
                              ...option,
                              label: t(option?.label),
                            }))
                            .find(item => item.value === Number(values.status))}
                          onChange={selectedOption =>
                            setFieldValue("status", selectedOption.value)
                          }
                          options={bankFinanceStatusOptions?.map(option => ({
                            ...option,
                            label: t(option?.label),
                          }))}
                          placeholder={t("Select Status")}
                          className={`select2-selection b_r_12 ${
                            touched.status && errors.status
                              ? "danger_color_border"
                              : ""
                          } `}
                        />
                        <CustomErrorMessage name={"status"} />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="form_field">
                        <CustomLabel htmlFor="note" label={t("Note")} />
                        <CustomField
                          name="note"
                          as="textarea"
                          placeholder={t("Note")}
                          touched={touched}
                          errors={errors}
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mt-3">
                        <button
                          className="primary_btn ms-auto d-block"
                          type="submit"
                          disabled={
                            isLoading ||
                            selectedItem?.bankFinanceDetails?.bankFinanceStep ==
                              values.status
                          }
                        >
                          {t("Change")}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Modal>
    </React.Fragment>
  )
}

export default BankFinanceRequest
