import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import { showAlert } from "store/reducers/alertReducer";
import { updateProject } from "store/actions/projectsAction";
import FileUpload from "components/Shared/FileUpload";
import FormButtons from "components/Shared/FormButtons";
import CustomLabel from "components/Shared/CustomLabel";
import CustomErrorMessage from "components/Shared/CustomErrorMessage";
import { updateUnit } from "store/actions/unitsAction";

const GeneralDetails = ({ id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);

  const floorPlan = useSelector((state) => state.Units.unitDetails.floorPlanUrl);
  const warrantyUpload = floorPlan?.floorPlanUrl || [];

  const initialValues = {
    floorPlanUrl: "",
  };

  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    if (floorPlan) {
      setFormValues({ floorPlan: floorPlan });
      setUploadedFile(floorPlan);
      setIsLoaded(true);
    }
  }, [floorPlan]);

  if (!id) {
    dispatch(showAlert({ text: "Project ID is required!", type: "error" }));
    return null;
  }

  const handleOnSubmit = (values) => {
    if (!values.floorPlan) {
      dispatch(showAlert({ text: "Please upload a warranty document!", type: "error" }));
      return;
    }

    const formData = new FormData();
    formData.append("id", id);
    formData.append("floorPlan", values.floorPlan);

    setIsLoading(true);
    dispatch(updateUnit(formData))
      .then((action) => {
        const { success, message } = action?.payload;
        dispatch(showAlert({ text: message, type: success ? "success" : "error" }));
      })
      .catch((error) => {
        console.error("Error uploading warranty document:", error);
        dispatch(showAlert({ text: "Upload failed. Try again.", type: "error" }));
      })
      .finally(() => setIsLoading(false));
  };

  const handleDeleteImage = () => {
    setSelectedId(uploadedFile);
    let formData = new FormData();
    formData.append("id", id);
    formData.append("floorPlan", "");

    dispatch(updateUnit(formData))
      .then((action) => {
        const { success, message } = action?.payload;
        if (success) {
          setUploadedFile(null);
          dispatch(showAlert({ text: "File deleted successfully!", type: "success" }));
        } else {
          dispatch(showAlert({ text: message, type: "error" }));
        }
        setSelectedId(null);
      })
      .catch((error) => {
        dispatch(showAlert({ text: "Failed to delete file!", type: "error" }));
      });
  };

  return (
    <div className="warranty_upload">
      <Formik initialValues={formValues} onSubmit={handleOnSubmit} enableReinitialize={true}>
        {({ values, errors, setFieldValue, touched }) => (
          <Form>
            <div className="form_field">
              <CustomLabel htmlFor="floorPlan" label={t("Upload Floor Plan")} isRequired />

              <FileUpload
                name="floorPlan"
                value={values.floorPlan}
                touched={touched.floorPlan}
                error={errors.floorPlan}
                labelText={t("Upload Warranty Document")}
                setFieldValue={setFieldValue}
                accept="application/pdf"
                fileType="pdf"
                handleFileUpload={(event) => {
                  const file = event.target.files[0];
                  if (file && file.type === "application/pdf") {
                    setFieldValue("floorPlan", file);
                    setUploadedFile(file);
                  } else {
                    dispatch(showAlert({ text: "Only PDF files are allowed!", type: "error" }));
                  }
                }}
                handleDeleteImage={handleDeleteImage}
              />



              <CustomErrorMessage name="floorPlan" />
            </div>

            <FormButtons buttonText={t("Upload")} isLoading={isLoading} />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default GeneralDetails;
