import React from "react";
import ImgPreview from "./ImgPreview";
import TrashIcon from "assets/images/icons/trash.svg";
import GalleryIcon from "assets/images/icons/gallery.svg";
import VideoIcon from "assets/images/icons/video.svg";
import PdfIcon from "assets/images/icons/pdf.svg"; // Add an icon for PDF files

import VideoPreview from "./VideoPreview";
import { t } from "i18next";
import { Link } from "react-router-dom";

const isPDFFile = (file) => {
  return file && (file.type === "application/pdf" || (typeof file === "string" && file.endsWith(".pdf")));
};

const FileUpload = ({
  name,
  value,
  touched,
  error,
  labelText,
  setFieldValue,
  handleFileUpload,
  accept = "image/jpeg, image/jpg, image/png, image/webp, application/pdf",
  fileType = "image",  // Default is image, but can be "pdf"
  customClasses = "",
  height = null,
  showNameOnly = false,
  showPreviewLink = false,
  isCustomDelete = false,
  handleVideoDelete = () => {},
  handleDeleteImage,
}) => {
  const previewUrl = value instanceof File ? URL.createObjectURL(value) : value;
  const isPDF = fileType === "pdf" || isPDFFile(value);  // Ensure fileType is checked

  return (
    <div>
      <label
        htmlFor={name}
        className={`file_label g_center position-relative ${customClasses} ${
          touched && error ? "text-danger border-danger" : ""
        }`}
        style={{ height: height || null }}
      >
        {value ? (
          <>
            {showNameOnly ? (
              showPreviewLink ? (
                <Link className="text-black text-decoration-underline" to={previewUrl} target="_blank">
                  {t("View Attachments")}
                </Link>
              ) : (
                <p>{value.name || value || "-"}</p>
              )
            ) : isPDF ? ( // Show PDF icon when fileType is "pdf"
              <div className="d-flex align-items-center">
                <img src={PdfIcon} alt="PDF Icon" width={24} height={24} className="mx-2" />
                <a href={previewUrl} target="_blank" rel="noopener noreferrer">
                  {t("View PDF")}
                </a>
              </div>
            ) : fileType === "video" ? (
              <VideoPreview url={value} />
            ) : (
              <ImgPreview image={value} />
            )}
            
            {/* Delete Button */}
            <img
              src={TrashIcon}
              alt="Trash Icon"
              width={24}
              height={24}
              className="cursor-pointer ms-3 trash_icon"
              onClick={e => {
                e.preventDefault();
                if (isCustomDelete) {
                  handleVideoDelete(setFieldValue);
                } else {
                  setFieldValue(name, "");
                }
                handleDeleteImage();
              }}
            />
          </>
        ) : (
          <>
            <img
              src={fileType === "video" ? VideoIcon : isPDF ? PdfIcon : GalleryIcon} // Use PdfIcon for PDFs
              alt="Upload Icon"
              className="mx-2"
            />
            <span>{labelText}</span>
          </>
        )}
      </label>
      
      <input
        name={name}
        type="file"
        id={name}
        accept={accept}
        className={`form-control d-none ${touched && error ? "is-invalid" : ""}`}
        onChange={event => handleFileUpload(event, setFieldValue, name)}
      />
    </div>
  );
};


export default FileUpload;
