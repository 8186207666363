import React, { useEffect, useState } from "react"
import { Card, Col, Container, Modal, Row, Table } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import useFilters from "components/Shared/useFilters"
import Title from "components/Shared/Title"
import Filters from "components/Shared/Filters"
import { commonFilterOptions } from "components/Common/constants"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import Switch from "components/Shared/Switch"
import { getDateRange, handleStopPropagation } from "components/Common/Utils"
import DeleteModal from "components/Common/DeleteModal"
import Paginations from "components/Common/Pagination"
import TrashIcon from "assets/images/icons/trash.svg"
import EditIcon from "assets/images/icons/edit.svg"
import TopTitle from "components/Shared/TopTitle"
import { useDispatch, useSelector } from "react-redux"

import Spinners from "components/Shared/Spinners"
import { showAlert } from "store/reducers/alertReducer"

import {
  createRoomType,
  deleteRoomType,
  getAllRoomTypes,
  getRoomTypeDetails,
  updateRoomType,
  updateRoomTypeStatus,
} from "store/actions/roomTypesAction"
import IsLoading from "components/Shared/IsLoading"
import { Form, Formik } from "formik"
import CustomLabel from "components/Shared/CustomLabel"
import CustomField from "components/Shared/CustomField"
import { createRoomTypeSchema } from "components/Common/validation"
import GalleryIcon from "assets/images/icons/pdf.svg"
const initialValues = { name: "", nameAr: "" }

const RoomTypes = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Room Types | Takween")

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { search } = useLocation()
  const pageNumber = Number(new URLSearchParams(search).get("page")) || 1

  const {
    loading,
    isLoading,
    totalCount,
    totalPages,
    currentPage,
    perPage,
    isGettingDetails,
    roomTypes,
    roomTypeDetails,
  } = useSelector(state => state.RoomTypes)

  const name = useSelector(state => state.Login.userProfile?.name) || ""

  const [formValues, setFormValues] = useState(initialValues)

  const [isModal, setIsModal] = useState(false)
  const [modalType, setModalType] = useState(null)

  const [
    filters,
    keyword,
    setKeyword,
    dateRange,
    setDateRange,
    flatpickrRef,
    selectedId,
    setSelectedId,
    itemIdToDelete,
    setItemIdToDelete,
    showDeleteModal,
    setShowDeleteModal,
    isApiCalled,
    setIsApiCalled,
    handleFilterChange,
    resetFilters,
  ] = useFilters({
    status: -1,
    orderBy: 2,
  })

  useEffect(() => {
    fetchAllRoomTypes()
  }, [])

  const fetchAllRoomTypes = page => {
    const { status, orderBy } = filters

    const payload = {
      perPage: perPage,
      pageNo: page || pageNumber,
      status: status,
      orderBy,
      keyword,
      dateRange: getDateRange(dateRange),
    }
    dispatch(getAllRoomTypes(payload))
    setIsApiCalled(true)

    navigate(`/room-types?page=${page || pageNumber}`)
  }

  useEffect(() => {
    isModal && modalType === "UPDATE" && fetchRoomType()
  }, [isModal])

  const fetchRoomType = () => {
    const payload = {
      id: selectedId,
    }
    dispatch(getRoomTypeDetails(payload))
  }

  useEffect(() => {
    if (roomTypeDetails) {
      const { name, nameAr } = roomTypeDetails
      setFormValues({
        name,
        nameAr,
      })
    }
  }, [roomTypeDetails])

  // RECALL API ONCHANGE FILTER
  useEffect(() => {
    if (isApiCalled) {
      handleSearch()
    }
  }, [keyword, filters])

  useEffect(() => {
    if (isApiCalled) {
      if (dateRange.min && dateRange.max) handleSearch()
    }
  }, [dateRange])

  const handleSearch = () => {
    fetchAllRoomTypes(1)
  }

  const onPageChange = page => {
    fetchAllRoomTypes(page)
  }

  const handleStatusClick = (id, status) => {
    setSelectedId(id)
    const payload = {
      id,
      status: status === 1 ? "0" : "1",
    }
    dispatch(updateRoomTypeStatus(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setSelectedId(null)
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  const handleDelete = () => {
    const payload = {
      id: itemIdToDelete,
      status: "2", // 2 FOR DELETE
    }
    dispatch(deleteRoomType(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setItemIdToDelete(null)
          setShowDeleteModal(false)
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  const handleOnSubmit = (values, { resetForm }) => {
    if (modalType === "CREATE") {
      dispatch(createRoomType(values))
        .then(action => {
          const { statusCode, success, message } = action?.payload
          if (statusCode === 200 && success) {
            dispatch(showAlert({ text: message, type: "success" }))
            setIsModal(false)
            resetForm()
          } else {
            dispatch(showAlert({ text: message, type: "error" }))
          }
        })
        .catch(error => {})
    } else if (modalType === "UPDATE") {
      const updatedPayload = {
        ...values,
        id: selectedId,
      }
      dispatch(updateRoomType(updatedPayload))
        .then(action => {
          const { statusCode, success, message } = action?.payload
          if (statusCode === 200 && success) {
            dispatch(showAlert({ text: message, type: "success" }))
            setIsModal(false)
          } else {
            dispatch(showAlert({ text: message, type: "error" }))
          }
        })
        .catch(error => {})
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <TopTitle
            title={t("Room Types")}
            subTitle={t(
              `Hi, {{name}}. Welcome back to your Marketing portal!`,
              { name: name }
            )}
            createBtnText={t("Create")}
            onCreateBtnClick={() => {
              setModalType("CREATE")
              setIsModal(true)
            }}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <Title title={t("Room Types List")} />
                <div className="pb-2"></div>
                <Filters
                  isGlobalFilter={true}
                  SearchPlaceholder={t("Search")}
                  keyword={keyword}
                  setKeyword={setKeyword}
                  filters={commonFilterOptions}
                  selectedFilters={filters}
                  handleFilterChange={handleFilterChange}
                  handleReset={() => resetFilters()}
                  isRangeDate={true}
                  flatpickrRef={flatpickrRef}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  isResetButton={true}
                />
                {loading ? (
                  <Spinners />
                ) : roomTypes.length === 0 ? (
                  <NoDataAvailable />
                ) : (
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="full_th">
                        <tr>
                          <th>{t("# ")}</th>
                          <th>{t("Name(English)")}</th>
                          <th>{t("Name(Arabic)")}</th>
                          <th>{t("Status")}</th>
                          <th>
                            <div
                              className="text-end pe-5"
                              style={{ marginRight: 40 }}
                            >
                              {t("Action")}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {roomTypes.map((item, index) => (
                          <tr key={item._id} onClick={() => {}}>
                            <th scope="row">
                              {(currentPage - 1) * perPage + index + 1}
                            </th>

                            <td>{item.name || "-"}</td>
                            <td>{item.nameAr || "-"}</td>
                            <td>
                              {item.status === 1 ? (
                                <p className="rended_status status_tag">
                                  {t("Active")}
                                </p>
                              ) : item.status === 0 ? (
                                <p className="reject_status status_tag">
                                  {t("Deactive")}
                                </p>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td onClick={handleStopPropagation}>
                              <div className="d-flex align-items-center justify-content-end pe-3">
                                <img
                                  src={EditIcon}
                                  alt="Edit Icon"
                                  width={24}
                                  height={24}
                                  className="cursor-pointer"
                                  onClick={() => {
                                    setModalType("UPDATE")
                                    setIsModal(true)
                                    setSelectedId(item._id)
                                  }}
                                />
                                <img
                                  src={TrashIcon}
                                  alt="Trash Icon"
                                  width={24}
                                  height={24}
                                  className="cursor-pointer mx-3"
                                  onClick={() => {
                                    setItemIdToDelete(item._id)
                                    setShowDeleteModal(true)
                                  }}
                                />
                                <Switch
                                  onClickHandler={() => {
                                    handleStatusClick(item._id, item.status)
                                  }}
                                  isChecked={item.status === 1 ? true : false}
                                  disabled={
                                    isLoading && selectedId === item._id
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card>
            </Col>
            {!loading && roomTypes.length > 0 && (
              <Paginations
                currentPage={currentPage}
                totalPages={totalPages}
                totalCount={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </Row>
        </Container>
      </div>

      <DeleteModal
        show={showDeleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => {
          setItemIdToDelete(null)
          setShowDeleteModal(false)
        }}
        isLoading={isLoading}
        buttonText={t("Delete")}
        bodyTitle={t("Are you sure you want to delete this {{name}}?", {
          name: t("Room Type"),
        })}
      />

      <Modal
        isOpen={isModal}
        toggle={() => {
          setIsModal(!isModal)
        }}
        className="g_modal w_600"
        centered
      >
        <h2 className="modal_title">
          {modalType === "CREATE"
            ? t("Create Room Type")
            : t("Update Room Type")}
        </h2>
        <button
          type="button"
          onClick={() => {
            setIsModal(!isModal)
            setSelectedId(null)
          }}
          className="modal_close_btn"
        >
          <i className="mdi mdi-close"></i>
        </button>
        {modalType === "UPDATE" && isGettingDetails ? (
          <IsLoading />
        ) : (
          <div>
            <Formik
              initialValues={formValues}
              onSubmit={handleOnSubmit}
              validationSchema={createRoomTypeSchema}
              enableReinitialize={true}
            >
              {({ touched, errors }) => (
                <Form>
                  <Row>
                    <Col lg={12}>
                      <div className="form_field">
                        <CustomLabel
                          htmlFor="name"
                          label={t("Name(English)")}
                          isRequired={true}
                        />
                        <CustomField
                          name="name"
                          type="text"
                          placeholder={t("Name(English)")}
                          touched={touched}
                          errors={errors}
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="form_field">
                        <CustomLabel
                          htmlFor="nameAr"
                          label={t("Name(Arabic)")}
                          isRequired={true}
                        />
                        <CustomField
                          name="nameAr"
                          type="text"
                          isArabic={true}
                          placeholder={t("Name(Arabic)")}
                          touched={touched}
                          errors={errors}
                        />
                      </div>
                    </Col>
                    <Col lg={13}>
          <div className="form_field">
            <CustomLabel
              htmlFor="image"
              label={t("Upload Gallery Images")}
              isRequired={true}
            />
          </div>
        </Col>
        <Col lg={14}>
        <label  className="file_label g_center position-relative ">
          <img src={GalleryIcon} alt="gallery Icon" className="mx-2"/><span>Upload Images</span></label>
          {/* <label
            htmlFor="galleryImage"
           class="file_label g_center position-relative 
          >
            <img src={GalleryIcon} alt="gallery Icon" className="mx-2" />
            <span>("Upload Images")</span>
          </label> */}
          <input
            name="galleryImage"
            type="file"
            id="galleryImage"
         
            multiple
            accept="image/jpeg, image/jpg, image/png"
            className="form-control d-none"
            // onChange={handleFileUpload}
          />
        </Col>
                    <Col lg={12}>
                      <button
                        className="save_btn mt-4 ms-auto d-block"
                        type="submit"
                        disabled={isLoading}
                      >
                        {modalType === "CREATE" ? t("Create") : t("Update")}
                      </button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>

          </div>
        )}
      </Modal>
    </React.Fragment>
  )
}

export default RoomTypes
