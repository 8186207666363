import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Formik, Form } from "formik"
import { useTranslation } from "react-i18next"
import { showAlert } from "store/reducers/alertReducer"
import { updateProject } from "store/actions/projectsAction"
import FileUpload from "components/Shared/FileUpload"
import FormButtons from "components/Shared/FormButtons"
import CustomErrorMessage from "components/Shared/CustomErrorMessage"

const Floorplan = ({ id }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState({}) // Store uploaded files
  const [isLoaded, setIsLoaded] = useState(false)
  const [uploadedFile, setUploadedFile] = useState(null)
  const [selectedId, setSelectedId] = useState(null)
  const { projectDetails } = useSelector(state => state.Projects)
  const floorList = projectDetails?.floorList || []
  if (!id) {
    dispatch(showAlert({ text: "Project ID is required!", type: "error" }))
    return null
  }
  const imageUrl = floorList.map(floor => floor.imageUrl || null)
  const initialValues = {
    floorList: floorList.map(floor => ({
      name: floor.name || "",
      nameAr: floor.nameAr || "",
      value: floor.value || 1,
      imageUrl: floor.imageUrl || null, // Store the existing image URL
      image: floor.imageUrl || null, // Ensure Formik recognizes the image URL
    })),
  }

  const [formValues, setFormValues] = useState(initialValues)

  const handleFileUpload = (event, index, setFieldValue) => {
    const file = event.target.files[0]
    if (file && file.type.startsWith("image/")) {
      setFieldValue(`floorList[${index}].image`, file) // Set file in Formik state
      setUploadedFiles(prev => ({ ...prev, [index]: file })) // Store file separately
    } else {
      dispatch(
        showAlert({ text: "Only image files are allowed!", type: "error" })
      )
    }
  }

  const handleOnSubmit = async values => {
    const formData = new FormData()
    formData.append("id", id)

    const updatedFloorList = values.floorList.map((floor, index) => {
      if (!floor.name || !floor.nameAr) {
        dispatch(
          showAlert({
            text: `Floor ${index + 1} name is required!`,
            type: "error",
          })
        )
        return floor
      }

      formData.append(`floorList[${index}][name]`, floor.name)
      formData.append(`floorList[${index}][nameAr]`, floor.nameAr)
      formData.append(`floorList[${index}][value]`, floor.value || 1)
      formData.append(`floorList[${index}][image]`, uploadedFiles[index])
      // Handling File Uploads
      if (floor.imageUrl) {
        // If no new file but has existing URL, send the URL
        formData.append(`floorList[${index}][imageUrl]`, floor.imageUrl || null)
      }

      return floor
    })

    setIsLoading(true)

    console.log(formData, "form Data")

    try {
      const response = await dispatch(updateProject(formData))

      if (response?.payload?.success) {
        dispatch(showAlert({ text: "Upload successful!", type: "success" }))
      } else {
        dispatch(
          showAlert({
            text: response?.payload?.message || "Upload failed",
            type: "error",
          })
        )
      }
    } catch (error) {
      console.error("Error uploading floor plans:", error)
      dispatch(showAlert({ text: "Upload failed. Try again.", type: "error" }))
    } finally {
      setIsLoading(false)
    }
  }
  const handleDeleteImage = values => {
    let formData = new FormData()
    formData.append("id", id)

    dispatch(updateProject(formData))
      .then(action => {
        const { success, message } = action?.payload

        if (success) {
          setUploadedFile(null)

          dispatch(
            showAlert({ text: "File deleted successfully!", type: "success" })
          )
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
        setSelectedId(null)
      })
      .catch(error => {
        dispatch(showAlert({ text: "Failed to delete file!", type: "error" }))
      })
  }

  return (
    <div className="floorplan_upload">
      <Formik
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <table className="table">
              <thead>
                <tr>
                  <th>{t("Floor Name(English)")}</th>
                  <th>{t("Floor Name(Arabic)")}</th>
                  <th>{t("Value")}</th>
                  <th>{t("Image")}</th>
                </tr>
              </thead>
              <tbody>
                {values.floorList.length > 0 ? (
                  values.floorList.map((floor, index) => (
                    <tr key={index}>
                      <td>{floor.name}</td>
                      <td>{floor.nameAr}</td>
                      <td>{floor.value}</td>
                      <td>
                        <FileUpload
                          name={`floorList[${index}].image`}
                          value={values.floorList[index].image}
                          touched={touched?.floorList?.[index]?.image}
                          error={errors?.floorList?.[index]?.image}
                          setFieldValue={setFieldValue}
                          accept="image/*"
                          fileType="image"
                          handleFileUpload={event =>
                            handleFileUpload(event, index, setFieldValue)
                          }
                          handleDeleteImage={() => {
                            setFieldValue(`floorList[${index}].image`, null)
                            setFieldValue(`floorList[${index}].imageUrl`, null)
                          }}
                        />
                        <CustomErrorMessage
                          name={`floorList[${index}].image`}
                        />
                      </td>
                      <td></td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center">
                      {t("No floor data available")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <FormButtons buttonText={t("Upload")} isLoading={isLoading} />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default Floorplan
